<template>
  <div class="grid-container" >
    <div class="col-position"
         :style="'min-width: ' + widthSize + '%!important; max-width: ' + widthSize + '%!important;'"
    >
      <products></products>
    </div>
    <div class="col-position"
         :style="'min-width: ' + widthSize + '%!important; max-width: ' + widthSize + '%!important;'"
    >
      <ticket></ticket>
    </div>
    <div class="col-position"
         :style="'min-width: ' + widthSize + '%!important; max-width: ' + widthSize + '%!important;'"
    ></div>
    <div class="col-position"
         :style="'min-width: ' + widthSize + '%!important; max-width: ' + widthSize + '%!important;'"
    ></div>
  </div>
</template>
<script>
export default {
  components: {
    Products: () => import('@custom/caisse/Products'),
    Ticket: () => import('@custom/caisse/Ticket'),
  },
  data: () => ({
    gridBloc: [
      {name: 'product', position: 1, show: true},
      {name: 'ticket', position: 2, show: true},
      {name: 'booking-open', position: 3, show: true},
      {name: 'booking-closed', position: 3, show: true},
      {name: 'credit', position: 4, show: true},
      {name: 'last', position: 4, show: true},
    ]
  }),
  computed: {
    widthSize() {
      const size = 100;
      const colAvailable = [];
      this.gridBloc.forEach(item => {
        if (item.show) {
          if (!colAvailable.includes(item.position)) {
            colAvailable.push(item.position);
          }
        }
      });
      if (colAvailable.length === 0) {
        return 0;
      }

      return size / colAvailable.length;
    }
  }
}
</script>
<style scoped lang="scss">
.grid-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .col-position {
    padding-left: 5px;
    padding-right: 5px;

  }
}
</style>
